var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "ma-0 pa-0 quizzes d-flex align-start justify-start accent3",
      attrs: { fluid: "", "fill-height": "" }
    },
    [
      !_vm.$route.meta.hidenavbar && _vm.$vuetify.breakpoint.mdAndUp
        ? _c("UserNavBar")
        : _vm._e(),
      _vm.$vuetify.breakpoint.smAndDown ? _c("UserAppBar") : _vm._e(),
      _c("router-view"),
      _c("confirm-dialog", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }