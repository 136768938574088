<template>
  <v-card>
    <v-navigation-drawer
      color="background"
      permanent
      app
      :expand-on-hover="$vuetify.breakpoint.mdAndDown"
      mini-variant-width="60"
      class="font-weight-bold text-h5"
    >
      <v-list>
        <v-list-item class="pb-4">
          <v-img v-if="$vuetify.breakpoint.lgAndUp" :src="logo_png" height="100" max-width="200" contain></v-img>
          <v-img v-else :src="qizto_icon" max-height="80" contain></v-img>
        </v-list-item>
      </v-list>
      <v-list nav v-for="link in links" :key="link.title">
        <v-list-item class="secondary--text" :to="{ name: link.route, params: link.params }">
          <v-list-item-icon>
            <font-awesome-icon :icon="link.icon" size="lg" />
          </v-list-item-icon>
          <v-list-item-title>{{ link.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list-item @click="handleLogout()" class="secondary--text">
        <v-list-item-icon>
          <font-awesome-icon icon="door-open" size="lg" />
        </v-list-item-icon>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
      <template v-slot:append>
        <v-list>
          <v-list-item v-if="userprofile">
            <v-list-item-avatar color="accent" class="white--text">
              {{ initials }}
              <v-img v-if="userprofile.image" src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle class="overline secondary--text">Logged In As</v-list-item-subtitle>
              <v-list-item-title class="text-h6 secondary--text"> {{ userprofile.name }} </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">{{ userprofile.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import { mapState, mapMutations } from "vuex"
import { logout } from "@/api/AuthApi"
export default {
  name: "UserNavBar",
  data() {
    return {
      logo_png: require("@/assets/common/Qizto_Logo.png"),
      light_mode_logo: require("@/assets/common/logo_lightmode.svg"),
      dark_mode_logo: require("@/assets/common/logo_darkmode.svg"),
      qizto_icon: require("@/assets/common/Qizto_Logo_Icon.png"),
      links: [
        {
          title: "New Quiz",
          icon: "square-plus",
          route: "Create",
          params: { type: "L" },
        },
        {
          title: "My Quizzes",
          icon: "dice-d20",
          route: "Quizzes",
        },
        {
          title: "Reports",
          icon: "file-contract",
          route: "sessions",
          params: { id: "all" },
        },
        {
          title: "Mailing List",
          icon: "envelopes-bulk",
          route: "Quizzes",
        },
        {
          title: "Profile",
          icon: "id-card",
          route: "Profile",
          params: { id: "user.uid" },
        },
      ],
      logoutSuccessMessage: "Logged Out Successfully",
      guestSignInMessage: "You are Logged in as Guest",
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.currentUser,
      userprofile: (state) => state.user.userProfile,
    }),
    initials() {
      return this.$store.getters.getInitials
    },
  },
  methods: {
    ...mapMutations({
      showAlert: "SHOW_SNACKBAR",
      setUser: "SET_CURRENT_USER",
      clearUserProfile: "CLEAR_USER_PROFILE_ON_SIGNOUT",
    }),

    updatePref(event) {
      this.updateDarkMode(event)
    },

    async handleLogout() {
      try {
        await logout()
        this.$router.push("/")
        this.setUser(null)
        this.clearUserProfile()
        this.showAlert(this.logoutSuccessMessage)
      } catch (err) {
        this.showAlert(err.message)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.v-sheet {
  border: 0px solid transparent !important;
}
</style>
