var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "font-weight-bold text-h5",
          attrs: {
            color: "background",
            permanent: "",
            app: "",
            "expand-on-hover": _vm.$vuetify.breakpoint.mdAndDown,
            "mini-variant-width": "60"
          },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function() {
                return [
                  _c(
                    "v-list",
                    [
                      _vm.userprofile
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-avatar",
                                {
                                  staticClass: "white--text",
                                  attrs: { color: "accent" }
                                },
                                [
                                  _vm._v(" " + _vm._s(_vm.initials) + " "),
                                  _vm.userprofile.image
                                    ? _c("v-img", {
                                        attrs: {
                                          src:
                                            "https://randomuser.me/api/portraits/women/85.jpg"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-subtitle",
                                    { staticClass: "overline secondary--text" },
                                    [_vm._v("Logged In As")]
                                  ),
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "text-h6 secondary--text" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.userprofile.name) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-list-item-subtitle",
                                    { staticClass: "secondary--text" },
                                    [_vm._v(_vm._s(_vm.userprofile.email))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { staticClass: "pb-4" },
                [
                  _vm.$vuetify.breakpoint.lgAndUp
                    ? _c("v-img", {
                        attrs: {
                          src: _vm.logo_png,
                          height: "100",
                          "max-width": "200",
                          contain: ""
                        }
                      })
                    : _c("v-img", {
                        attrs: {
                          src: _vm.qizto_icon,
                          "max-height": "80",
                          contain: ""
                        }
                      })
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.links, function(link) {
            return _c(
              "v-list",
              { key: link.title, attrs: { nav: "" } },
              [
                _c(
                  "v-list-item",
                  {
                    staticClass: "secondary--text",
                    attrs: { to: { name: link.route, params: link.params } }
                  },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: link.icon, size: "lg" }
                        })
                      ],
                      1
                    ),
                    _c("v-list-item-title", [_vm._v(_vm._s(link.title))])
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "v-list-item",
            {
              staticClass: "secondary--text",
              on: {
                click: function($event) {
                  return _vm.handleLogout()
                }
              }
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "door-open", size: "lg" }
                  })
                ],
                1
              ),
              _c("v-list-item-title", [_vm._v("Logout")])
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }