async function sendMail(emailcontent) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(emailcontent),
  }
  const response = await fetch("https://us-central1-vquiz-dev.cloudfunctions.net/sendMail", requestOptions)
  return response
}

export { sendMail }
