var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      style: { zIndex: _vm.options.zIndex },
      attrs: { "max-width": _vm.options.width },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.cancel.apply(null, arguments)
        }
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: _vm.options.color, dense: "", flat: "" } },
            [
              _c("v-toolbar-title", { staticClass: "white--text" }, [
                _vm._v(" " + _vm._s(_vm.title) + " ")
              ])
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !!_vm.message,
                  expression: "!!message"
                }
              ],
              staticClass: "pa-4 font-weight-medium text-h6"
            },
            [_vm._v(" " + _vm._s(_vm.message) + " ")]
          ),
          _c(
            "v-card-actions",
            { staticClass: "pt-0" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "error" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.agree.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(" Yes ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.cancel.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }