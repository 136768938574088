<template>
  <v-container class="ma-0 pa-0 quizzes d-flex align-start justify-start accent3" fluid fill-height>
    <UserNavBar v-if="!$route.meta.hidenavbar && $vuetify.breakpoint.mdAndUp"></UserNavBar>
    <UserAppBar v-if="$vuetify.breakpoint.smAndDown"></UserAppBar>
    <router-view> </router-view>
    <confirm-dialog ref="confirm"></confirm-dialog>
  </v-container>
</template>

<script>
import UserNavBar from "@/components/common/UserNavBar.vue"
import UserAppBar from "@/components/common/UserAppBar.vue"
import ConfirmDialog from "@/components/common/ConfirmDialog.vue"
import { mapMutations, mapState } from "vuex"
import { deleteQuizAttachments, deleteQuiz, deleteQuizSessions } from "@/api/QuizApi"
import { createGameSession } from "@/api/GameApi"
import { sendMail } from "@/api/EmailApi"

export default {
  name: "Dashboard",
  components: {
    UserNavBar,
    UserAppBar,
    ConfirmDialog,
  },
  mounted() {
    this.$eventBus.$on("startquiz", this.initializeQuiz)
    this.$eventBus.$on("deletequiz", this.deleteQuiz)
  },
  beforeDestroy() {
    this.$eventBus.$off("startquiz")
    this.$eventBus.$off("deletequiz")
  },
  destroyed() {
    this.quizListListener()
  },
  computed: {
    ...mapState({
      user: (state) => state.user.currentUser,
      quizListListener: (state) => state.quiz.quizListListener,
    }),
  },
  methods: {
    ...mapMutations({ showAlert: "SHOW_SNACKBAR", clearGameData: "CLEAR_GAME_DATA" }),
    async initializeQuiz(data, emaillist) {
      this.clearGameData()
      try {
        const routeparam = await createGameSession(data, emaillist)
        const quizpin = routeparam.split("-").slice(-1)
        if (data.type === "L") {
          this.$router.push({ name: "Presenter", params: { id: routeparam } })
        } else {
          sendMail({
            to: emaillist,
            subject: `You've been invited to play a quiz by ${this.user.displayName}`,
            message: `Visit https://qizto.com/join and enter the Quiz Pin ${quizpin} to start participating in the quiz`,
          }).then((res) => {
            console.log(res)
            this.showAlert(res.message)
          })
        }
      } catch (err) {
        this.showAlert(err.message)
      }
    },

    async deleteQuiz(id) {
      if (
        await this.$refs.confirm.open(
          "Delete",
          "Are you sure, All Data in this quiz will be gone forever. You will lose all sessions and score data ?",
          { color: "red" }
        )
      ) {
        try {
          await Promise.all([deleteQuizAttachments(this.user.uid, id), deleteQuizSessions(id)]).then(() => {
            deleteQuiz(id)
            this.snacktext = "Quiz Deleted Successfully!"
            this.showAlert(this.snacktext)
            if (!this.$route.name === "Quizzes") {
              this.$router.push("/dashboard")
            }
          })
        } catch (err) {
          this.snacktext = err
          this.showAlert(this.snacktext)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-toolbar {
  border-bottom: 2px solid var(--v-accent2-base) !important;
}
</style>
